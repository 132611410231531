.flexcontainer{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 100vw;
}

.gridcontainer{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 50%;
    margin: auto;
    justify-items: center;
}

.image{
    grid-column: span 2;
}

.container{
    margin: 20px;
}

.classifiedText{
    grid-column: span 2;
}

.header{
    margin: auto;
    text-align: center;
}

.inputBox{
    max-height: 30px;
    margin: 0.5em;
    grid-column: span 2;
    width: 100%;
}

.runButton{
    max-height: 50px;
    margin: 0.5em;
    grid-column: span 2;
    width: 100%;
}

.nextButton{
    margin: 0.5em;
    grid-column: span 1;
    width: 20vw;
}

.prevButton{
    margin: 0.5em;
    grid-column: span 1;
    width: 20vw;
}

@media screen and (max-width: 600px){
    .header{
        margin-top: 10vh;
    }
    .classifiedText{
        margin-top: 20vh;
    }
    .gridcontainer{
        width: 70%;
        align-items: center;
    }
}