button, button:active, button:focus {
    outline: none;
}

button{
    max-height: 50px;
}

.image{
    margin: 0.5em;
    grid-column: span 2;
    width: 50%;
    justify-content: center;
}

.gridcontainer{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 50%;
    margin: auto;
    justify-items: center;
}

.flexcontainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100vw;
}

.header{
    margin: auto;
    text-align: center;
}

.description{
    margin: 20px;
    width: 100%;
}

.inputBox{
    max-height: 50px;
    margin: 0.5em;
    margin-top: 8vh;
    grid-column: span 2;
    width: 100%;
}

.runButton{
    max-height: 50px;
    margin: 0.5em;
    grid-column: span 2;
    width: 100%;
}

.nextButton{
    margin: 0.5em;
    grid-column: span 1;
    width: 20vw;
}

.prevButton{
    margin: 0.5em;
    align-content: right;
    grid-column: span 1;
    width: 20vw;
}

@media screen and (max-width: 600px){
    .image{
        margin-top: 20vh;
    }
    .header{
        margin-top: 10vh;
    }
    .gridcontainer{
        width: 70%;
    }
}