.rightCard{
    background-color: #eeeeee;
    margin-left: 2vw;
    border-radius: 10px;
    margin-right: 2vw;
    width: 96vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5vh;
}

.rightCard img{
    max-width: 45vw;
    margin-left: 2vw;
    margin-top: 2vh;
}

.rightCard h1{
    margin: 2vh;
    text-align: center;
    font-size: 1.5rem;
    color: black;
}

.rightCard p{
    font-size: 1rem;
    color: black;
}

.leftCard{
    background-color: #eeeeee;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 10px;
    width: 96vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5vh;
}

.rightGrid p{
    margin-left: 1vw;
}

.leftCard img{
    max-width: 45vw;
    margin-right: 2vw;
    margin-top: 2vh;
    grid-row: span 2;
}

.leftCard h1{
    margin: 2vh;
    text-align: center;
    font-size: 1.5rem;
    color: black;
}

.leftCard p{
    margin: 2vh;
    font-size: 1rem;
    color: black;
}

a:hover{
    text-decoration: none;
}


@media screen and (min-width: 800px){
    .leftCard{
        width: 45vw;
        margin-right: 0;
    }
    .leftCard img{
        max-width: 20vw;
        margin-top: 1vh;
    }
    .rightCard img{
        max-width: 20vw;
        margin-top: 1vh;
    }
    .rightCard{
        width: 45vw;
        margin-left: 0;
        margin-right: 4vw;
    }
}