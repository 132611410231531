.centred{
    margin: auto;
    width: 50%;
}

table{
    width: 100%;
}

/*Builds a card around the network*/
.networkCard{
    margin-top: 3vh;
    background: #eeeeee;
    text-align: center;
}

.networkHeader{
    margin: 1rem;
}

.networkDescription{
    margin: 1rem;
}

.networkContainer{
    height: 80vh;
    width: 79vw;  
}

.tableContainer{
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-color: #eeeeee;    
}

/*Handles the case where the network container needs to be hidden*/
.networkContainerNull{
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.tableParameters{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5vh;
    height: 10vh;
}

.titleInputPair{
    margin: 1vh 1vw;
}

.parameterSelector{
    max-height: 100%;
}

.reloadButton{
    max-height: 100%;
    margin: 0;
}

.outline{
    border: 1px solid grey
}

tr:nth-child(even) {
    background-color: #dddddd;
}
tr:nth-child(odd){
    background-color: rgb(0,0,40);
    color: white;
}

.chartContainer{
    width: 100%;
    height: 80vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-color: #eeeeee; 
}

.chartWithParametersContainer{
    width: 100%;
    height: 80%;
}

.gridcontainer{
    display: grid;
    grid-template-columns: 100%;
    width: 80%;
    margin: auto;
    justify-items: center;
}

.image{
    grid-column: span 2;
}

.container{
    margin: 20px;
}


.header{
    margin: auto;
    text-align: center;
}

.inputBox{
    max-height: 30px;
    margin: 0.5em;
    grid-column: span 2;
    width: 100%;
}


.nextButton{
    margin: 0.5em;
    grid-column: span 1;
    width: 20vw;
}

.prevButton{
    margin: 0.5em;
    grid-column: span 1;
    width: 20vw;
}


@media screen and (max-width: 600px){
    .header{
        margin-top: 10vh;
    }
    .gridcontainer{
        width: 100%;
        align-items: center;
    }
}