.flexContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.responseMessage{
    margin: auto;
    margin-top: 5vh;
}
.leftContainer{
    display: grid;
    width: 50%
}

.rightContainer{
    display: flex;
    flex-wrap: wrap;
    width: 50%
}

.Headline{
    margin: auto;
}

#lastName{
    margin: auto;
    margin-top: 10px;
    width: 50%;
}

#firstName{
    margin: auto;
    margin-top: 10px;
    width: 50%;
}

#email{
    margin: auto;
    margin-top: 10px;
    width: 50%
}

.nameInputs{
    margin: auto;
}

.submitButton{
    margin: auto;
    margin-top: 5vh;
}
.messageText{
    margin: auto;
    margin-top: 10px;
    width: 50%;
}

#mediaTitle{
    margin: auto;
}

.mediaLinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: 10px;
}

#warning{
    margin: auto;
}

.mediaLinks img{
    overflow: hidden;
    max-width: 5vw;
    max-height: 5vh;
}

@media screen and (max-width: 870px){
    .leftContainer{
        width: 100%;
    }
    .rightContainer{
        width: 100%;
    }
    .Headline{
        text-align: center;
        margin-top: 15vh;
    }
    #mediaTitle{
        text-align: center;
        margin-top: 10vh;
    }
}