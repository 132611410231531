.mainContainer{
    display: flex;
    flex-wrap: wrap;
}
.Header{
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: 8px;
    padding: 1rem;
    width: 100vw;
    text-align: center;
}

.Photo{
    max-width: 94vw;
    margin-left: 2vw;
    border-radius: 10px;
    margin-bottom: 5vh;
}
.Description{
    background-color: #eeeeee;
    border-radius: 50px;
    margin: auto;
    width: 96vw;
    padding: 5vw;
    margin-top: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 5vh;
}

.projectCardContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}


@media screen and (min-width: 800px){
    .Photo{
        max-width: 45vw;
        margin-left: 2vw;
    }
    .Description{
        max-width: 45vw;
        padding: 5vw;
        margin: auto;
        margin-top: 0;
    }
}