/*Removes dashed line from around anchor tags when clicked*/
a, a:active, a:focus{
    outline: none;
}

button, button:active, button:focus {
    outline: none;
}
button{
    margin-top: 20px;
}

.NavBar{
    position: sticky;
    width: 100%;
    height: 8vh;
    font-size: 1rem;
    top: 0;
    z-index: 4;
}

.VertNavBar{
    position: fixed;
    z-index: 3;
    height: 100%;
    width: 70%;
    align-items: flex-start;
}

.container{
    margin: auto;
    max-width: 180px;
}

.Hamburger{
    display: none;
}

.brandBurger{
    cursor: pointer;
    max-width: 100px;
}

.cornerLogo{
    max-height: 50px;
}

@media screen and (max-width: 600px){
    .NavBar{
        display: none;
    }
    .Hamburger{
        display: block;
        max-width: 100px;
        position: fixed;
    }
    .Hamburger:hover{
        cursor: pointer;
    }
}